import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { api } from '../utils';
import { COLORS } from './colors';
import { daysAscending } from '../utils/common';

const Table = () => {
  const [fetchingData, setFetchingData] = useState(true);
  const [day, setDay] = useState('');
  const [slots, setSlots] = useState([]);
  const [schedule, setSchedule] = useState({
    days: [],
  });

  useEffect(() => {
    async function fetchData() {
      const response = await api.GET('public-table');
      response.days = response.days.sort(daysAscending);
      setFetchingData(false);
      setSchedule(response);
      const [firstDay] = response.days;
      if (firstDay && !day) {
        setDay(firstDay.id);
      }
    }
    fetchData();
  }, []);

  useEffect(
    () => {
      const displayDay = schedule.days.find(({ id }) => id === parseInt(day, 10));
      if (displayDay) setSlots(displayDay.slots);
    },
    [day],
  );

  if (fetchingData) {
    return null;
  }

  return (
    <Container className="container">
      <h4 className="left-align">Tabel publicat</h4>
      <h5>
        Lista cu programările pentru spovedanie se actualizează în fiecare duminică de la ora 21:30.
      </h5>

      <div className="input-field col s12">
        <select className="browser-default" value={day} onChange={(event) => setDay(event.target.value)}>
          <option value="" disabled>Alegeți o zi</option>
          {schedule.days.map(({ id, name }) => (
            <option key={id} value={id}>{name}</option>
          ))}
        </select>
      </div>

      <div style={{ marginTop: '40px' }}>

        {slots.map((slot) => {
          const status = slot.userName ? COLORS.INTERVAL : COLORS.USER;

          return (
            <StyledTable className="row">
              <div className={`col s4 ${slot.color || status}`}>
                {slot.name.replace(' - 00:00', '') || '-'}
                {slot.phone && (
                  <>
                    <br />
                    <span>&nbsp; </span>
                  </>
                )}
              </div>
              <div className={`col s8 ${slot.color || status} truncate`}>
                <span style={{ textDecoration: slot.active === 2 ? 'line-through' : 'none' }}>
                  {slot.userName || '-' }
                </span>

                {slot.phone && (
                  <>
                    <br />
                    <span><a href={`tel:${slot.phone}`} style={{ color: '#FFF' }}>{slot.phone}</a></span>
                  </>
                )}

                {slot.active === 2 && (<span> (anulat)</span>)}
              </div>
            </StyledTable>
          );
        })}
      </div>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 20px;

  h5 {
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 20px;
  }
`;

const StyledTable = styled.div`
  margin-bottom: 10px;

  && > div {
    padding: 10px;
    color: #FFF;
    font-weight: bold;

    &:first-child {
      border-right: 1px solid #FFF;
    }
  }
`;

export default Table;
